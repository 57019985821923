import router from './../router';
import { createStore } from 'vuex'
import { initializeApp } from "firebase/app";
import Notiflix from 'notiflix';
import { 
    getFirestore, 
    collection, 
    doc, 
    getDoc, 
    getDocs, 
    updateDoc,
    deleteDoc,
    setDoc 
} from "firebase/firestore";
import { 
    getAuth,
    signOut, 
    signInWithEmailAndPassword,
    signInWithCredential,
    GoogleAuthProvider,
    signInWithPopup
} from "firebase/auth";
import { 
    getStorage, 
    ref, 
    // listAll, 
    getDownloadURL, 
    uploadBytes 
} from "firebase/storage";

const app = initializeApp({
    apiKey: "AIzaSyCr4QpZWHadKIA2c3FSP8hdKeU5yKkv7EI",
    authDomain: "ionds-app.firebaseapp.com",
    projectId: "ionds-app",
    storageBucket: "ionds-app.appspot.com",
    messagingSenderId: "560081886266",
    appId: "1:560081886266:web:44a21de31633c99da7e903"
});

const DB = getFirestore(app);
const AUTH = getAuth(app);
const STORAGE = getStorage();
const GOOGLE_PROVIDER = new GoogleAuthProvider();

Notiflix.Notify.init({
	width: '300px',
	position: 'right-top',
	distance: '15px',
	opacity: 1,
	useFontAwesome: true,
    // timeout: 3000,
	fontAwesomeIconSize: '20px',
	cssAnimationStyle: 'from-top',
	fontFamily: 'Source Sans Pro',
	failure: {
		background: '#FF6565',
		textColor: '#fff',
		notiflixIconColor: '#fff',
		fontAwesomeClassName: 'fas fa-trash-alt',
		fontAwesomeIconColor: '#fff',
	},
	warning: {
		background: '#ff793f',
		textColor: '#fff',
		notiflixIconColor: '#fff',
		fontAwesomeClassName: 'fas fa-exclamation-triangle',
		fontAwesomeIconColor: '#fff',
	},
	success: {
		background: '#11BCA9',
		textColor: '#fff',
		notiflixIconColor: '#fff',
		fontAwesomeClassName: 'fas fa-check',
		fontAwesomeIconColor: '#fff',
	}
});

function getColectionFromDatabase(c) {
	return getDocs(collection(DB, c));
}
function getDocFromDatabase(c, d) {
	return getDoc(doc(DB, c, d));
}
function setDocToDatabase(c, d, data) {
    return setDoc(doc(DB, c, d), data);
}
function updateDocToDatabase(c, d, data) {
    return updateDoc(doc(DB, c, d), data);
}
function deleteDocFromDatabase(c, d) {
    return deleteDoc(doc(DB, c, d));
}

function setLs(key, value) {
	localStorage.setItem(key, JSON.stringify(value));
}
// function getLs(key) {
// 	return JSON.parse(localStorage.getItem(key));
// }
function removeFromLs(key) {
	localStorage.removeItem(key);
}

export default createStore({
    state: {
        isDarkMode: true,
        users: [],
        authStatuses: {
            isLoggedIn: false,
            loginInProcess: false,
            errorMsg: ''
        },
        user: {},
        projects: [],
        sharedProjects: [],
        logs: [],
        general: {
            help: ''
        }
    },
    getters: {
        isDarkMode(state) {
            return state.isDarkMode;
        },
        getUserInfo(state) {
            return function (uid) {
                return state.users.find(user => {
                    return user.uid == uid;
                })
            }
        },
        getProjectById(state) {
            return function (id) {
                return state.projects.find(project => {
                    return project.id == id;
                })
            }
        },
        getHelpContent(state) {
            return state.general.help.content;
        },
        getAllProjects(state) {
            return state.projects.filter(el => {
                return (el.status == 'private' && el.author != state.user.uid) ? false : true;
            });
        },
        getUserProjects(state) {
            return state.projects.filter(el => {
                return (el.author === state.user.uid);
            })
        },
        getUsers(state) {
            return state.users;
        },
        getLogs(state) {
            return state.logs;
        },
        getUser(state) {
            return state.user;
        },
        isLoggedIn(state) {
            return state.authStatuses.isLoggedIn;
        },
        getAuthStatuses(state) {
            return state.authStatuses;
        }
    },
    mutations: {
    },
    actions: {
        loginWithGoogle(context) {
            signInWithPopup(AUTH, GOOGLE_PROVIDER)
				.then(paramsCredential => {
                    context.dispatch('signInWithGoogleToken', paramsCredential);
				})
				.catch((error) => {
					context.state.authStatuses.errorMsg = error.message;
					context.state.authStatuses.loginInProcess = false;
                    router.replace('/auth');
				})
        },
        signInWithGoogleToken(context, paramsCredential) {
			context.state.authStatuses.loginInProcess = true;
            const newCredential = GoogleAuthProvider.credentialFromResult(paramsCredential);
            signInWithCredential(AUTH, newCredential)
                .then(credential => {
                    context.dispatch('login', credential);
                    setLs('ionds-user', credential);
                })
                .catch((error) => {
                    context.state.authStatuses.errorMsg = error.message;
                    context.state.authStatuses.loginInProcess = false;
                    router.replace('/auth');
                    removeFromLs('ionds-user');
                })
        },
        loginWithEmailAndPassword(context, user) {
			context.state.authStatuses.loginInProcess = true;
            signInWithEmailAndPassword(AUTH, user.email, user.password)
				.then(credential => {
                    context.dispatch('login', credential);
                    setLs('ionds-user', user);
				})
				.catch((error) => {
					context.state.authStatuses.errorMsg = error.message;
					context.state.authStatuses.loginInProcess = false;
                    router.replace('/auth');
                    removeFromLs('ionds-user');
				})
        },
        login(context, credential) {
            getDocFromDatabase('Users', credential.user.uid)
                .then(responce => {
                    if (!responce.data()) {
                        context.dispatch('cteateUser', credential.user)
                            .then(() => {

                            })
                            .catch((error) => {
                                context.state.authStatuses.errorMsg = error.message;
                                context.state.authStatuses.loginInProcess = false;
                                return;
                            });
                    } else {
                        console.log('APP: This user is already exist!')
                        context.state.user = responce.data();
                    }
                    // Wait for data load
                    Promise.all([
                        context.dispatch('fetchUsers'), 
                        context.dispatch('fetchProjects')
                    ]).then(() => {
                        context.state.authStatuses.loginInProcess = false;
                        context.state.uid = credential.user.uid;
                        context.state.authStatuses.isLoggedIn = true;
                        if (router.currentRoute._value.name == 'auth') router.replace('/');
                    });
                })
                .catch((error) => {
					context.state.authStatuses.errorMsg = error.message;
					context.state.authStatuses.loginInProcess = false;
                    router.replace('/auth');
                    removeFromLs('ionds-user');
				}) 
        },
        cteateUser(context, userCred) {
            context.state.user = {
                uid: userCred.uid,
                email: userCred.email,
                avatar: (userCred.photoURL) ? userCred.photoURL : '',
                friends: [],
                projects: {
                    personal: [],
                    shared: []
                },
                activity: [],
                role: 'user',
                status: 'offline',
                bio: {
                    phone: '',
                    name: '',
                    description: '',
                    university: ''
                }
            };
            return setDocToDatabase('Users', userCred.uid, context.state.user);
        },
        uploadFile(context, params) {
            let imageRef = ref(STORAGE, params.path)
            return uploadBytes(imageRef, params.file)
                .then((snapshot) => {
                    return getDownloadURL(snapshot.ref)
                });
            },
        logout(context) {
            removeFromLs('ionds-user');
            signOut(AUTH).then(() => {
                context.state.authStatuses.isLoggedIn = false;
			})
        },
        showNotification(context, params) {
            Notiflix.Notify[params.type](params.text);
        },
        saveUserInfo(context, user) {
			return setDocToDatabase('Users', user.uid, user);
		},
        saveHelpContent(context, content) {
			return updateDocToDatabase('General', 'Help', {
                content: content
            });
		},
        deleteProject(context, id) {
            return deleteDocFromDatabase('Projects', id);
        },
        saveProject(context, project) {
			return setDocToDatabase('Projects', project.id, project);
		},
        addLog(context, logData) {
            return setDocToDatabase('Logs', logData.id, logData);
        },
        updateLog(context, logData) {
            return updateDocToDatabase('Logs', logData.id, logData);
        },
        fetchHelpContent(context) {
            getDocFromDatabase('General', 'Help')
                .then(responce => {
                    context.state.general.help = responce.data();
                })
        },
        fetchProjects(context) {
            getColectionFromDatabase('Projects')
                .then(responce => {
                    context.state.projects = [];
                    responce.forEach(project => {
                        context.state.projects.push(project.data());
                    });
                })
        },
        fetchUsers(context) {
            getColectionFromDatabase('Users')
                .then(responce => {
                    context.state.users = [];
                    responce.forEach(user => {
                        context.state.users.push(user.data());
                    });
                });
        },
        fetchLogs(context) {
            getColectionFromDatabase('Logs')
                .then(responce => {3
                    context.state.logs = [];
                    responce.forEach(log => {
                        context.state.logs.push(log.data());
                    });
                });
        },
        updateUserActivity(context, data) {
            context.state.user.activity = (data.activity.length > 100) ? data.activity.slice(0, -1) : data.activity;
            return updateDocToDatabase('Users', data.uid, {
                activity: context.state.user.activity
            });
        },
        toggleTheme(context, theme) {
            context.state.isDarkMode = theme;
            localStorage.setItem('theme', (theme) ? 'dark' : 'light');
            if (theme)
                document.head.querySelector('meta[name="theme-color"]').setAttribute('content', '#161b1f');
            else
                document.head.querySelector('meta[name="theme-color"]').setAttribute('content', '#F8F9FA');
        }
    },
    modules: {

    }
})
